<template>
  <section class="p-2">
    <h2>{{ $t('service.fuel.prices.title') }}</h2>
    <b-row>
      <b-col class="d-flex justify-content-center mb-1" cols="12">
        <h4 class="mb-1">{{ $t('service.fuel.prices.automatic_importation') }}</h4>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="d-flex mb-1" cols="12">
        <span>{{ $t('common.note') }} {{ $t('service.fuel.prices.import_note') }}</span>
      </b-col>
    </b-row>

    <validation-observer ref="importCSV" tag="form" @submit.prevent="product.postImportCSV()">
      <b-row>
        <b-col cols="9">
          <b-form-input id="importation-with-url" v-model="product.mainVariant.importUrl" placeholder="https://example.com" required type="url" />
        </b-col>

        <b-col>
          <b-button block @click="downloadFuelPriceCSV()">{{ $t('action.download_example') }}</b-button>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="9">
          <p>{{ $t('service.fuel.prices.informations_about_automatic_import') }}</p>
        </b-col>

        <b-col>
          <b-button v-if="$route.name !== 'productShow'" :disabled="product.mainVariant.importUrl === product._reference.mainVariant.importUrl" block type="submit">{{
            $t('action.import')
          }}</b-button>
        </b-col>

        <b-col v-if="product._reference.mainVariant.importUrl">
          <b-button
            block
            variant="danger"
            @click="
              () => {
                product.mainVariant.importUrl = null
                product.postImportCSV()
              }
            "
          >
            {{ $t('action.delete') }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>

    <b-row class="row-spacer-or">
      <header class="header-choice my-2">
        <div class="div-choice">
          <span class="spacer-choice" />
          <h1 class="h1-choice">{{ $t('common.or') | capitalize }}</h1>
          <span class="spacer-choice" />
        </div>
      </header>
    </b-row>

    <b-row>
      <b-col class="d-flex justify-content-center mb-1" cols="12">
        <h4 class="mb-1">{{ $t('service.fuel.prices.one_time_importation') }}</h4>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="d-flex mb-1" cols="12">
        <span>{{ $t('common.note') }} {{ $t('service.fuel.prices.import_note') }}</span>
      </b-col>
    </b-row>

    <validation-observer
      ref="uploadCSV"
      tag="form"
      @submit.prevent="
        () => {
          product.postUploadCSV().then(() => {
            refreshAirportsTable()
          })
        }
      "
    >
      <b-row>
        <b-col cols="9">
          <b-overlay :show="dropZoneLoading" class="mt-1" spinner-medium>
            <app-drop-zone
              id="upload-files"
              ref="uploadFiles"
              :options="dropzoneOptions"
              @vdropzone-queue-complete="iconUploaded = true"
              @vdropzone-removed-file="removePlainFile($event)"
              @vdropzone-file-added="sendPlainFile($event)"
            />
          </b-overlay>
        </b-col>

        <b-col class="mt-1">
          <b-button block @click="downloadFuelPriceCSV()">{{ $t('action.download_example') }}</b-button>
          <b-button v-if="$route.name !== 'productShow'" :disabled="!product.file" block class="mt-2" type="submit">{{ $t('action.import') }}</b-button>
        </b-col>
      </b-row>
    </validation-observer>

    <hr class="my-3" />

    <div class="my-2">
      <b-row>
        <b-col class="d-flex align-items-center justify-content-start" cols="12" md="8" order="2" order-md="1">
          <b-form-select
            v-model="perPage"
            :disabled="negotiatedRatesLoading"
            :options="perPageOptions"
            class="per-page-selector mr-md-3 mr-1"
            @change="refreshAirportsTable()"
          />
          <!-- search and filter-->
          <app-data-table-top-search
            :disabled="negotiatedRatesLoading"
            :filter-options="searchFilterOptions"
            class="mr-1"
            condensed="md"
            default-filter="icao"
            table-name="fuel-certificate"
            @search="
              search => {
                searchQuery = search
                refreshAirportsTable()
              }
            "
          />
        </b-col>

        <!-- action -->
        <b-col v-if="$route.name !== 'productShow'" class="d-flex align-items-center justify-content-end mb-1 mb-md-0" cols="12" md="4" order="1" order-md="2">
          <b-button class="d-flex align-items-center justify-content-center" @click="airportFuelMode = 'add'">
            <font-awesome-icon class="mx-50" icon="user-plus" />
            {{ $t('service.fuel.prices.add_an_airport') }}
          </b-button>
        </b-col>
      </b-row>

      <b-tabs fill>
        <b-tab class="tab" style="width: 33.333% !important" @click="groupFieldsIndex = 0">
          <template #title style="width: 33.333% !important">
            <div class="title">
              {{ $t('service.fuel.prices.general_informations') }}
            </div>
          </template>
        </b-tab>
        <b-tab class="tab" @click="groupFieldsIndex = 1">
          <template #title>
            <div class="title">
              {{ `${$t('service.fuel.prices.base_price')} & ${$t('service.fuel.prices.airport_and_logistic_fees')}` }}
            </div>
          </template>
        </b-tab>
        <b-tab class="tab" @click="groupFieldsIndex = 2">
          <template #title>
            <div class="title">{{ `${$t('service.fuel.prices.into_plane_uplift')} & ${$tc('pricing.tax', 2)}` }}</div>
          </template>
        </b-tab>

        <b-table
          ref="fuelListTable"
          :empty-text="$t('common.no_record_found')"
          :fields="displayedFields"
          :items="fetchPrice"
          class="w-100"
          primary-key="id"
          responsive
          show-empty
          striped
        >
          <template #thead-top>
            <!-- HEAD TOP -->
            <b-tr class="fuel-price-categories">
              <b-th :colspan="groupFieldsIndex === 0 ? '5' : '1'" class="airport-color">
                {{ $t('service.fuel.prices.airport') }}
              </b-th>
              <b-th v-if="groupFieldsIndex === 0" class="general-information-price-color" colspan="3">
                {{ $t('service.fuel.prices.general_informations') }}
              </b-th>
              <b-th v-if="groupFieldsIndex === 1" class="base-price-color" colspan="2">
                {{ $t('service.fuel.prices.base_price') }}
              </b-th>
              <b-th v-if="groupFieldsIndex === 1" class="airport-and-logistic-fee-color" colspan="3">
                {{ $t('service.fuel.prices.airport_and_logistic_fees') }}
              </b-th>
              <b-th v-if="groupFieldsIndex === 2" class="into-plane-uplift-color" colspan="2">
                {{ $t('service.fuel.prices.into_plane_uplift') }}
              </b-th>
              <b-th v-if="groupFieldsIndex === 2" class="vat-color" colspan="2">
                {{ $tc('pricing.tax', 2) }}
              </b-th>
              <b-th colspan="1" />
            </b-tr>
          </template>

          <!-- CELL -->
          <template #cell(startAt)="data">{{ $moment(data.item.startAt).format('ddd, MMM Do YYYY') }}</template>
          <template #cell(endAt)="data">{{ $moment(data.item.endAt).format('ddd, MMM Do YYYY') }}</template>
          <template #cell(country)="data">{{ data.item.country ? data.item.country.name : 'Not defined' }}</template>
          <template #cell(public)="data">
            <b-form-checkbox v-model="data.item.public" class="ml-1" disabled />
          </template>
          <!-- Base price -->
          <template #cell(commercialFlights)="data">{{ data.item.commercialFlights }} {{ $getMeasurementUnitItn('epl') }}</template>
          <template #cell(commercialMarkUp)="data">{{ data.item.commercialMarkUp }} {{ $getMeasurementUnitItn('epl') }}</template>
          <template #cell(airportConcessionFee)="data">{{ data.item.airportConcessionFee }} {{ $getMeasurementUnitItn('epl') }}</template>
          <!-- Airport logistic fees -->
          <template #cell(storageFee)="data">{{ data.item.storageFee }} {{ $getMeasurementUnitItn('epl') }}</template>
          <template #cell(otherHydrantFee)="data">{{ data.item.otherHydrantFee }} {{ $getMeasurementUnitItn('epl') }}</template>
          <!-- Into Plain Uplift -->
          <template #cell(upliftPricePerQuantityUnit)="data">{{ data.item.upliftPricePerQuantityUnit }} {{ $getMeasurementUnitItn('epl') }}</template>
          <template #cell(hookUpFee)="data">{{ data.item.hookUpFee }} {{ getCurrency() }}</template>
          <!-- Prices tax -->
          <template #cell(vatRate)="data">{{ data.item.vatRate }} %</template>
          <template #cell(co2)="data">{{ data.item.motAndCarbonDioxide }} {{ $getMeasurementUnitItn('epl') }}</template>

          <template #cell(actions)="data">
            <app-data-table-actions
              :show-add="false"
              :show-duplicate="false"
              :show-show="false"
              display-mode="inline-icon"
              :show-delete="$can('SERVICE_EDIT')"
              :show-edit="$can('SERVICE_EDIT')"
              table-name="category-products-name"
              @delete="removeAirportFuel(data.item.id)"
              @edit="editAirportFuel(data.item)"
            />
          </template>
        </b-table>

        <!--PAGINATION-->
        <app-data-table-bottom
          :page="page"
          :per-page="perPage"
          :total-count="dataMeta.totalItems"
          class="m-2"
          table-name="fuel-price"
          @pagination="page = $event"
        />

        <product-fuel-price-form v-model="airportFuelMode" :airport-fuel="selectedAirportFuel" @refresh-airports-table="refreshAirportsTable()" />
      </b-tabs>
    </div>

    <footer-form-service class="mt-3" next previous @click:previous="$emit('previous-tab')" @click:next="$emit('next-tab')" />
  </section>
</template>

<script>
import { computed, defineComponent, inject, ref } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vue2Dropzone from 'vue2-dropzone'

import { downloadFuelPriceCSV } from '@/request/globalApi/requests/productFuelRequests'
import { postFileRequest } from '@/request/globalApi/requests/fileRequests'
import getCurrency from '@/helpers/getCurrency'
import AppDropZone from '@/components/AppDropZone.vue'
import AppDataTableTopSearch from '@/components/AppDataTableTopSearch.vue'
import AppDataTableBottom from '@/components/AppDataTableBottom.vue'
import AppDataTableActions from '@/components/AppDataTableActions.vue'
import ProductFuelPriceForm from '../component/ProductFuelPriceForm.vue'
import FooterFormService from '../component/FooterFormService.vue'

export default defineComponent({
  name: 'GeneralPriceManagementFuel',

  components: {
    vueDropzone: vue2Dropzone,
    ProductFuelPriceForm,
    AppDropZone,
    FooterFormService,
    AppDataTableTopSearch,
    AppDataTableBottom,
    AppDataTableActions,
  },

  setup(_props, ctx) {
    const { $i18n, $bvModal, alert, toaster } = ctx.root

    const dataMeta = ref({
      totalItems: 0,
      firstPage: 1,
      previousPage: null,
      nextPage: null,
      lastPage: null,
    })

    const fuelListTable = ref(null)
    const refreshAirportsTable = () => {
      fuelListTable.value.refresh()
    }

    const product = inject('product')

    const airportFuelMode = ref(null)
    const perPage = ref(10)
    const page = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchFilterOptions = [{ text: $i18n.t('airport.icao'), value: 'icao' }]
    const negotiatedRatesLoading = ref(false)

    const searchQuery = ref('')
    let sort = {}

    const fetchPrice = (btable, callback) => {
      if (btable.sortBy) sort = { [btable.sortBy]: btable.sortDesc ? 'desc' : 'asc' }

      const params = {
        page: page.value,
        numberOfItemsPerPage: perPage.value,
        searchIcao: searchQuery.value ? searchQuery.value : null,
        ...sort,
      }
      product.value.fetchPrice(params, ctx.root.$route.params.service_id, ctx.root.$route.query.vid).then(res => {
        dataMeta.value = res

        if (callback) {
          callback(product.value.fuelVariantPrices)
        }
      })
    }

    const dropZoneLoading = ref(false)
    const iconUploaded = ref(false)

    const dropzoneOptions = {
      autoProcessQueue: false,
      url: 'https://localhost',
      dictDefaultMessage: $i18n.t('common.csv.upload'),
      thumbnailWidth: 250,
      maxFiles: 1,
      maxFilesize: 2000000,
      headers: { 'My-Awesome-Header': 'header value' },
      acceptedFiles: '.csv',
      addRemoveLinks: true,
      clickable: true,
      uploadMultiple: false,
    }

    const uploadFiles = ref(null)

    const sendPlainFile = file => {
      if (file.size > dropzoneOptions.maxFilesize) {
        toaster($i18n.t('file.max_file_size_reached'), 'danger', 'AlertTriangleIcon')
        uploadFiles.value.removeFile(file)
        return
      }
      // If from API, do nothing
      if (file.id) return

      // If from device
      dropZoneLoading.value = true
      iconUploaded.value = false
      const formData = new FormData()
      formData.append('temporary_file', file)

      postFileRequest(formData)
        .then(response => {
          product.value.file = response.data.id
        })
        .finally(() => {
          iconUploaded.value = true
          dropZoneLoading.value = false
        })
    }

    const removePlainFile = () => {
      product.value.file = null
    }

    const removeAirportFuel = id => {
      $bvModal
        .msgBoxConfirm($i18n.t('alert.delete_confirmation.message'), {
          title: $i18n.t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: $i18n.t('common.yes'),
          cancelTitle: $i18n.t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async isConfirmed => {
          if (isConfirmed) {
            await product.value.deletePrice(id)
            alert($i18n.t('alert.delete.success'))
            refreshAirportsTable()
          }
        })
    }

    const groupFieldsIndex = ref(0)

    const fields = {
      airport: [
        { key: 'country', label: $i18n.t('common.country') },
        { key: 'icao', label: $i18n.t('airport.icao') },
        { key: 'iata', label: $i18n.t('airport.iata') },
        { key: 'supplier', label: $i18n.t('service.fuel.prices.supplier') },
        { key: 'upliftCompany', label: $i18n.t('service.fuel.prices.uplift_company') },
      ],
      generalInformations: [
        {
          key: 'startAt',
          sortKey: 'startAtOrder',
          label: $i18n.t('common.start_at'),
          sortable: true,
        },
        {
          key: 'endAt',
          sortKey: 'endAtOrder',
          label: $i18n.t('common.end_at'),
          sortable: true,
        },
        { key: 'public', label: $i18n.t('service.fuel.prices.public_price') },
      ],
      basePrice: [
        { key: 'commercialFlights', label: 'Commercial Flights' },
        { key: 'commercialMarkUp', label: $i18n.t('service.fuel.prices.markup') },
        { key: 'airportConcessionFee', label: $i18n.t('service.fuel.prices.airport_concession') },
      ],
      airportLogisticFees: [
        { key: 'storageFee', label: $i18n.t('service.fuel.prices.storage') },
        { key: 'otherHydrantFee', label: $i18n.t('service.fuel.prices.other_hydrant_fees') },
      ],
      intoPlainUplift: [
        { key: 'upliftPricePerQuantityUnit', label: $i18n.t('service.fuel.prices.uplift_cost_per_unit') },
        { key: 'hookUpFee', label: $i18n.t('service.fuel.prices.hook_up_fees') },
      ],
      pricesTax: [
        { key: 'vatRate', label: $i18n.t('common.vat') },
        { key: 'co2', label: $i18n.t('service.fuel.prices.excise_and_ecotax'), sortable: true },
      ],
    }

    const displayedFields = computed(() => {
      const getGroupFields = {
        0: () => fields.airport.concat(fields.generalInformations),
        1: () => [fields.airport[1]].concat(fields.basePrice).concat(fields.airportLogisticFees),
        2: () => [fields.airport[1]].concat(fields.intoPlainUplift).concat(fields.pricesTax),
      }
      return getGroupFields[groupFieldsIndex.value]().concat([{ key: 'actions', label: $i18n.tc('action.title', 2) }])
    })

    return {
      product,
      fetchPrice,
      uploadFiles,
      airportFuelMode,
      searchQuery,
      dataMeta,
      perPage,
      page,
      perPageOptions,
      searchFilterOptions,
      negotiatedRatesLoading,
      downloadFuelPriceCSV,
      sendPlainFile,
      removePlainFile,
      iconUploaded,
      dropZoneLoading,
      groupFieldsIndex,
      fields,
      displayedFields,
      dropzoneOptions,
      removeAirportFuel,
      fuelListTable,
      refreshAirportsTable,
      getCurrency,
    }
  },

  data() {
    return {
      statusFilter: '',
      selectedAirportFuel: {},
      totalUsers: 0,
    }
  },
  computed: {
    ...mapState('fuel', ['airportsFuel']),
  },
  methods: {
    ...mapActions('fuel', ['fetchAirportsFuel']),
    editAirportFuel(airportFuel) {
      this.airportFuelMode = 'edit'
      this.selectedAirportFuel = airportFuel
    },
  },
  mounted() {
    this.fetchAirportsFuel()
  },
})
</script>
<style lang="scss" scoped>
.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: transparent;
}
.airport-color {
  background-color: #5ea6ff !important;
  opacity: 0.75;
  color: $theme-dark-body-bg !important;
}
.general-information-price-color {
  background-color: #ffaa5b !important;
  opacity: 0.75;
  color: $theme-dark-body-bg !important;
}
.base-price-color {
  background-color: #ff5e5e !important;
  opacity: 0.75;
  color: $theme-dark-body-bg !important;
}
.airport-and-logistic-fee-color {
  background-color: #ffff7f !important;
  opacity: 0.75;
  color: $theme-dark-body-bg !important;
}
.into-plane-uplift-color {
  background-color: #94ff7c !important;
  opacity: 0.75;
  color: $theme-dark-body-bg !important;
}
.vat-color {
  background-color: blue !important;
  opacity: 0.75;
}
.accises-color {
  background-color: purple !important;
  opacity: 0.75;
}

.per-page-selector {
  width: 65px;
}

.header-choice {
  display: table;
  width: 100%;
  max-width: 100%;
}

.div-choice {
  display: table-row;
  line-height: 1.5em;
  font-size: 2em;
  white-space: nowrap;
}

.h1-choice {
  font-size: inherit;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
  width: 1px;
  table-layout: fixed;
  padding: 0 10px;
  color: #dcc181;
}

.spacer-choice {
  display: table-cell;
}

.spacer-choice:after {
  display: inline-block;
  width: 100%;
  content: '.';
  font-size: 0;
  color: transparent;
  height: 1px;
  padding: 0 -10px;
  background: #dcc181;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
.row-spacer-or {
  overflow: hidden;
  display: initial;
}
</style>
